//TODO REVISAR este y sus componentes hijos

class suggestionPage extends Component {

    static name() {
        return "suggestionPage";
    }

    static componentName() {
        return "suggestionPage";
    }

    goBack(){
        this.$router.go(-1);
    }

    getMethods(){
        return {
            goBack:this.goBack,
        };
    }

    getTemplate(){
        return `<div class="items-view">
                  <headerSectionComponent></headerSectionComponent>
                  <div class="section white">
                      <div :class="classContainer">
                          <template v-if="$store.getters.getAppState=='ready'">
                                <registerModalComponent></registerModalComponent>
                                <loginPage></loginPage>
                              <div class="row">
                                  <div class="left sticky-component items-category-tree">
                                      <homeCategorySectionComponent :only_icons_view=true></homeCategorySectionComponent>
                                  </div>                                
                                  <div class="col m8 s12">
                                      <suggestionListComponent></suggestionListComponent>
                                  </div>
                                  <div class="col m2 s12 sticky-component right">
                                      <userSectionComponent></userSectionComponent>
                                      <carComponent></carComponent>
                                  </div>
                              </div>

                          </template>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
                </div>`;
    }
}

suggestionPage.registerComponent();

/*

Vue.component('suggestionComponent', {
    methods:{
        goBack:function(){
            this.$router.go(-1);
        }
    },
    template: `<div class="items-view">
                  <headerSectionComponent></headerSectionComponent>
                  <div class="section white">
                      <div class="container">
                          <template v-if="$store.getters.getAppState=='ready'">
                                <registerComponent></registerComponent>
                                <loginComponent></loginComponent>
                              <div class="row">
                                  <div class="left sticky-component items-category-tree">
                                      <homeCategorySectionComponent :only_icons_view=true></homeCategorySectionComponent>
                                  </div>                                
                                  <div class="col m8 s12">
                                      <suggestionListComponent></suggestionListComponent>
                                  </div>
                                  <div class="col m2 s12 sticky-component right">
                                      <userSectionComponent></userSectionComponent>
                                      <carComponent></carComponent>
                                  </div>
                              </div>

                          </template>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
                </div>`
});
*/
